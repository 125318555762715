<template>
<div>
    <van-nav-bar title="胸腔穿刺" left-text="返回"  right-text=""  left-arrow  @click-left="onClickLeft" @click-right="onClickRight"  />
    <section class="van-doc-demo-section">
        <div class="van-doc-demo-block">
            <h2 class="van-doc-demo-block__title">学习模块</h2>
            <van-grid :border="false" :column-num="3">
                <van-grid-item>
                    <div class="content-block" @click="tourl(1)">视频学习</div>
                </van-grid-item>
                <van-grid-item>
                    <div class="content-block"  @click="tourl(2)">课件资料</div>
                </van-grid-item>
                <van-grid-item>
                </van-grid-item>
            </van-grid>
            
        </div>
         <div class="van-doc-demo-block">
            <h2 class="van-doc-demo-block__title">作业模块</h2>
            <van-grid :border="false" :column-num="3">
                <van-grid-item>
                    <div class="content-block"  @click="tourl(3)">视频作业</div>
                </van-grid-item>
                <van-grid-item>
                    <div class="content-block"  @click="tourl(4)">操作评价</div>
                </van-grid-item>
                <van-grid-item>
                </van-grid-item>
            </van-grid>
        </div>
         <div class="van-doc-demo-block">
            <h2 class="van-doc-demo-block__title">评价模块</h2>
            <van-grid :border="false" :column-num="3">
                <van-grid-item>
                    <div class="content-block"  @click="tourl(5)">评价记录</div>
                </van-grid-item>
                <van-grid-item>
                   
                </van-grid-item>
                <van-grid-item>
                </van-grid-item>
            </van-grid>
        </div>
    </section>
</div>

</template>

<script>
export default {
  data() {
    return {
      active: "b",
      guideType: true,
      videoType: true,
    }
  },
  methods: {
    // 点击左上角返回
    onClickLeft() {
      this.$router.push({ name: "home" });
    },
    onClickRight(){},  //此处不要删除，导航返回组件里自带的
    tourl(item){
      alert(item);
    },
    // 点击关闭视频
    closeVideo() {
      this.videoType = false;
    },
    // 点击开启视频引导
    openVideo() {
      this.videoType = true;
    },
    // 点击关闭引导
    closeGuide() {
      this.guideType = !this.guideType;
    }
  },
  mounted() { },
}
</script>

<style>
.van-doc-demo-section {
    
    box-sizing: border-box;
    min-height: calc(100vh - 56px);
    padding-bottom: 20px;
}

.van-doc-demo-block{
    padding: 20px 20px 0px;
}
.van-doc-demo-block__title {
    margin: 0;
    padding: 0px 5px 0px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border-left: 2px solid #8f9195;
    /* border-bottom: 1px solid #f7f8fa; */
    
}
.content-block{
    padding: 20px 20px 20px 20px;
  
    border: 2px solid rgb(210, 210, 210);
    border-radius:2px;
}

</style>